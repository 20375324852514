// extracted by mini-css-extract-plugin
export var dark = "r_r6";
export var darkcookie = "r_r7";
export var tintedcookie = "r_r8";
export var regularcookie = "r_r9";
export var darkbase = "r_sb";
export var tintedbase = "r_sc";
export var regularbase = "r_sd";
export var darkraw = "r_sf";
export var tintedraw = "r_sg";
export var regularraw = "r_sh";
export var darkchick = "r_sj";
export var tintedchick = "r_sk";
export var regularchick = "r_sl";
export var darkherbarium = "r_sm";
export var tintedherbarium = "r_sn";
export var regularherbarium = "r_sp";
export var darkholiday = "r_sq";
export var tintedholiday = "r_sr";
export var regularholiday = "r_ss";
export var darkoffline = "r_st";
export var tintedoffline = "r_sv";
export var regularoffline = "r_sw";
export var darkorchid = "r_sx";
export var tintedorchid = "r_sy";
export var regularorchid = "r_sz";
export var darkpro = "r_sB";
export var tintedpro = "r_sC";
export var regularpro = "r_sD";
export var darkrose = "r_sF";
export var tintedrose = "r_sG";
export var regularrose = "r_sH";
export var darktimes = "r_sJ";
export var tintedtimes = "r_sK";
export var regulartimes = "r_sL";
export var darkwagon = "r_sM";
export var tintedwagon = "r_sN";
export var regularwagon = "r_sP";