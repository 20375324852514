// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_sQ d_fn d_bF d_dt";
export var alignLeft = "s_p4 d_fn d_bF d_dt";
export var alignDiscCenter = "s_sR d_fp d_bC d_dv";
export var alignCenter = "s_bN d_fp d_bC d_dv";
export var alignDiscRight = "s_sS d_fq d_bG d_dw";
export var alignRight = "s_p5 d_fq d_bG d_dw";
export var footerContainer = "s_sT d_dV d_bV";
export var footerContainerFull = "s_sV d_dS d_bV";
export var footerHeader = "s_kd d_kd";
export var footerTextWrapper = "s_sW d_v";
export var footerDisclaimerWrapper = "s_kl d_kl d_cj";
export var badgeWrapper = "s_sX d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "s_sY undefined";
export var footerDisclaimerLeft = "s_sZ undefined";
export var verticalTop = "s_s0 d_by d_bH d_bM d_bK";
export var firstWide = "s_s1";
export var disclaimer = "s_s2 d_by d_bH";
export var socialDisclaimer = "s_s3";
export var left = "s_s4";
export var wide = "s_s5 d_cx";
export var right = "s_s6 d_bJ";
export var line = "s_fj d_fk d_ct";
export var badgeDisclaimer = "s_s7 d_bB d_bN d_bH";
export var badgeContainer = "s_s8 d_by d_bG d_bN";
export var badge = "s_s9";
export var padding = "s_tb d_c6";
export var end = "s_tc d_bG";
export var linkWrapper = "s_td d_dz";
export var link = "s_mY d_dz";
export var colWrapper = "s_tf d_cw";
export var consent = "s_f d_f d_bB d_bN";
export var media = "s_tg d_bw d_dx";
export var itemRight = "s_th";
export var itemLeft = "s_tj";
export var itemCenter = "s_tk";
export var exceptionWeight = "s_tl q_rj";