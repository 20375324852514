// extracted by mini-css-extract-plugin
export var campaignContainer = "l_pK d_dV";
export var campaignContainerFull = "l_pL d_dS";
export var wrapper = "l_pM d_v";
export var leftWrapper = "l_pN d_by d_bM";
export var rightWrapper = "l_pP";
export var subtitle = "l_pQ";
export var title = "l_pR";
export var text = "l_pF d_cr";
export var inlineWrapper = "l_pS d_bB d_bF d_v";
export var btnsWrapper = "l_pT l_pS d_bB d_bF d_v d_bH";
export var priceWrapper = "l_pV l_pS d_bB d_bF d_v d_cg d_bN";
export var priceHeading = "l_pW";
export var disclaimerWrapper = "l_pX d_cC";
export var mediaSlot = "l_pY";
export var listWrapper = "l_pZ d_bB d_bN d_v";
export var btnWrapper = "l_d1";
export var imageWrapper = "l_p0 d_ff d_Y";
export var SmallSmall = "l_p1 q_p1 q_qS q_rd";
export var SmallNormal = "l_p2 q_p2 q_qS q_rf";
export var SmallLarge = "l_p3 q_p3 q_qS q_rb";