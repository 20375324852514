// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_tC d_bC";
export var storyRowWrapper = "w_hw d_hw d_bJ";
export var storyLeftWrapper = "w_tD d_by d_bN";
export var storyWrapperFull = "w_tF d_cC";
export var storyWrapperFullLeft = "w_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "w_n9 d_hx";
export var storyLeftWrapperCenter = "w_tG d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "w_tH d_hD";
export var storyHeader = "w_tJ d_hC d_v d_cr";
export var storyHeaderCenter = "w_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "w_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "w_tK d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "w_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "w_p0 d_ff d_Y";
export var imageWrapperFull = "w_tL d_v d_G d_bd d_Y";