// extracted by mini-css-extract-plugin
export var alignLeft = "D_p4 d_fn d_bF d_dt";
export var alignCenter = "D_bN d_fp d_bC d_dv";
export var alignRight = "D_p5 d_fq d_bG d_dw";
export var element = "D_vG d_cr d_cf";
export var customImageWrapper = "D_vH d_cr d_cf d_Y";
export var imageWrapper = "D_p0 d_cr d_Y";
export var masonryImageWrapper = "D_pv";
export var gallery = "D_vJ d_v d_by";
export var width100 = "D_v";
export var map = "D_vK d_v d_G d_Y";
export var quoteWrapper = "D_gP d_bB d_bN d_cr d_cf d_dt";
export var quote = "D_vL d_bB d_bN d_dt";
export var quoteBar = "D_pn d_G";
export var quoteText = "D_pp";
export var customRow = "D_pD d_bC d_Y";
export var separatorWrapper = "D_vM d_v d_by";
export var articleText = "D_n2 d_cr";
export var videoIframeStyle = "D_pj d_d4 d_v d_G d_bx d_b0 d_Q";